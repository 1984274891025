.app  {
  height: 100vw;
  display: flex;
  flex-direction: column;
}
.app-header {
  height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid #e5e5e5;
}
.app-body {
  flex: 1;
  display: flex;
}
.logo {
  width: 120px;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  font-weight: bold;
}

.app-side {
  width: 230px;
  border-right: 1px solid #e5e5e5;
}
.app-content {
  flex: 1;
}
.app-content-inner {
  padding: 20px;
}
.menu-item {
  display: block;
  line-height: 40px;
  padding-left: 20px;
  text-decoration: none;
}
.ant-form-item {
  display: inline-block;
}
.ant-form-item-label {
  width: 100px;
}
.ant-form-item-control-input,
.ant-input-number,
.ant-picker  {
  width: 200px;
}
.sub-btn {
  margin-left: 100px;
}

.param-item {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.param-item .sp1 {
  width: 185px;
  margin-right: 10px;
  text-align: right;
}
.param-item .sp2 {
  width: 250px;
  margin-right: 20px;
  color: #999;
}


.print .title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
}
.print .order-num {
  text-align: right;
  margin-bottom: 20px;
  color: #999;
  font-size: 12px;
}
.print .info-item {
  margin-bottom: 20px;
  color: #666;
}
.print .ant-input-number {
  border: none;
  outline: none;
  height: 30px;
  width: 100px;
}
.print  .base-info {
  border-bottom: 1px dashed #e5e5e5;
  margin-bottom: 20px;
  position: relative;
}
.print .base-info .info-item {
  width: 40%;
  display: inline-flex;
  color: #666;
  line-height: 32px;
}
.print .base-info .info-item span {
  color: #333;
  display: inline-block;
  width: 100px;
  text-align: right;
  
}
.print .base-info .info-item .selector {
  width: 160px;
}
.print .ant-select {
  width: 160px;
}
.print .product-info {
  font-size: 13px;
  padding-top: 5px;
}
.print table {
  width: 100%;
  border-collapse: collapse;
}
.print td,
.print th {
  width: 100px;
  padding-left: 10px;
  height: 35px;
  border: 1px solid #e5e5e5;
}
.print .barcode-wrapper {
  text-align: right;
  margin-right: -9px;
}

